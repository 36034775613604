<template>
  <router-view />
</template>

<style>
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  height: 100vh;
  width: 100%;
  margin: 0;
}
</style>
