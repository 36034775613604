import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not-Found',
    redirect: {
      name: 'Login',
    },
  },
  {
    path: '/inicio',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
  },
  {
    path: '/vehiculos',
    name: 'VehiclesList',
    component: () =>
      import(/* webpackChunkName: "VehiclesList" */ '../views/CarsList.vue'),
  },
  {
    path: '/detalle',
    name: 'VehicleDetail',
    component: () =>
      import(
        /* webpackChunkName: "VehicleDetail" */ '../views/VehicleDetail.vue'
      ),
  },
  {
    path: '/editar',
    name: 'VehicleEdit',
    component: () =>
      import(/* webpackChunkName: "VehicleEdit" */ '../views/VehicleEdit.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
